<template>
  <div v-wechat-title="title" style="margin-bottom:100px">
    <div class="text-center text-bold text-lg margin-top">
      {{ detaildata.player_name }}
    </div>

    <div v-if="detaildata.show_pic" class="more-pics">
      <div v-for="item in detaildata.show_pic.split(',')" :key="item" class="more-pics-item">
        <img :style="{width:(windowWidth-100) + 'px',height:(windowWidth-100) + 'px'}" :src="baseSrc + item" mode="aspectFit">
      </div>
    </div>
    <div class="flex justify-around margin-top">
      <van-button icon="good-job-o" type="info" @click="submitreal()">投票</van-button>
    </div>
    <van-dialog v-model="show" title="关注公众号" message-align="center" @confirm="resintpage">
      <img class="imgpic" src="http://static.dreamacro.com/publicpic/gzh">
      <!-- <img class="imgpic" src="../../utils/1.jpg"> -->
    </van-dialog>
  </div>
</template>

<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import { getvoteappreal, postvoteappreal, getjdk } from '@/api/votepage.js'
// import wx from 'weixin-js-sdk'
export default {
  data() {
    return {
      id: '',
      title:"",
      code:'',
      detaildata: {},
      windowWidth: document.documentElement.clientWidth,
      baseSrc: 'http://cdn.dreamacro.com/',
      openid:'',
      originid:'',
       show: false
    }
  },

  mounted() {
    if (window.location.search.slice(1)) {
      const link = window.location.search.slice(1).split('&')
      this.originid = decodeURIComponent(link[1].split('=')[1])
      this.id = decodeURIComponent(link[0].split('=')[1])
    }
    this.init()
  },
  methods: {
    async init() {
      const res = await getvoteappreal(this.id)
      this.title = res.data.launch_org
      this.detaildata = res.data
    },
  back(){
      this.$router.push({ name: 'Votepage',query: { id:0,originid: this.originid,title: this.detaildata.launch_org} })
  },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      // eslint-disable-next-line no-new-object
      var theRequest = new Object()
      if (url.indexOf('?') !== -1) {
        var str = url.substr(1)
        var strs = str.split('&')
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
        }
      }
      return theRequest
    },
    resintpage() {
      this.getinit()
    },
    async getinit() {
      this.code = ''
      // var appid = 'wxa9b1061989663c5c' // 公众号
      var appid = 'wx79d1a256e0353320' // 线上公众号
      var local = window.location.href
      this.code = this.getUrlCode().code
      if (this.code === null || this.code === '' || this.code === undefined) {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=ok#wechat_redirect'
      }else{
      this.init()
      }
    },
    async submitreal() {
      const openid = localStorage.getItem('openid')
      const res = await postvoteappreal(this.id, openid)
      if (res.code === 0) {
        this.$toast.success('投票成功')
      }else{
        if (res.msg === '未关注公众号') {
          this.show = true
        } else {
          this.$toast.success(res.msg)
        }
      }
    },
  }
}
</script>

<style scoped>
	.more-pics {
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.more-pics-item {
		background-color: #F7F8FA;
	}
  .text-center {
    text-align: center;
}
.text-bold {
    font-weight: bold;
}
.text-lg {
    font-size: 16px;
}
.margin-top {
    margin-top: 15px;
}
.justify-around {
    justify-content: space-around;
}
.flex {
    display: flex;
}
.imgpic{
  margin: auto;display: flex; width:200px;height:200px
}
</style>
