import request from '@/utils/request'

export function getvoteappindex(id, code, wechat_type) {
  return request({
    url: '/real/app/index/' + id,
    method: 'get',
    params: {
      code,
      wechat_type
    }
  })
}

export function getvoteapprank(id) {
  return request({
    url: '/real/app/rank/' + id,
    method: 'get'
  })
}

export function postvoteappreal(id, open_id) {
  return request({
    url: '/real/' + id,
    method: 'post',
    data: {
      open_id
    }
  })
}

export function getvoteappreal(id, code) {
  return request({
    url: '/real/' + id,
    method: 'get',
    params: {
      code
    }
  })
}

export function getjdk(url) {
  return request({
    url: '/wx/jssdk',
    method: 'post',
    data: {
      url
    }
  })
}
